<div class="modal-header">
    <div class="d-block">
        <h4 class="modal-title" id="modal-basic-title" *ngIf="title">{{ title }}</h4>
        <div class="brandline" *ngIf="title"></div>
    </div>
    <button type="button" class="close-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="subtitle !== ''" class="d-flex align-items-center justify-content-center mb-4">
        <div>
            <h3 class="mb-3">{{ subtitle }}</h3>
            <div class="brandline"></div>
        </div>
    </div>
    <div *ngIf="idTicket !== ''" class="d-flex align-items-center justify-content-center">
        <h5 class="mb-0 text-center">
            Tu solicitud ha sido enviada con éxito<br> Ticket <span class="fw-bold">{{idTicket}}</span>
        </h5>
    </div>
    <div class="d-flex align-items-center mt-2">
        <img *ngIf="icon === 'exito'" src="../../../../../assets/images/icons/Modal/exito.png" class="mr-2">
        <img *ngIf="icon === 'info'" src="../../../../../assets/images/icons/Modal/info.png" class="mr-2">
        <div>
            <div *ngIf="showContent === true" class="d-flex align-items-center">
                <p class="mb-0">{{ content }}</p>
            </div>
            <ng-container *ngIf="detail">
                <ol [type]="type" class="modal-list mt-4">
                    <li *ngFor="let item of listContent" class="mt-3">{{ item.item }}</li>
                </ol>
            </ng-container>
        </div>
    </div>
</div>

<div class="modal-footer">

    <button *ngIf="icon === 'exito'" type="button" (click)="finishProcess()"
        class="rounded-5 px-5 btn btn-Primary col-lg-auto col-12">Aceptar</button>
    <button *ngIf="icon != 'exito'" type="button" (click)="activeModal.dismiss('Cross click')"
        class="rounded-5 px-5 btn btn-Primary col-lg-auto col-12">Aceptar</button>
</div>