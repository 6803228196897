<app-side-bar class="d-none d-lg-block" *ngIf="!hideComponent" [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"></app-side-bar>
<div class="container-fluid">
  <div class="row">
    <app-header class="show-header" *ngIf="!hideComponent"></app-header>
  </div>
  <div class="row sidebarMobile" *ngIf="!hideComponent">
    <app-side-bar-mobile class="d-block d-lg-none"></app-side-bar-mobile>
  </div>
  <div class="row">
    <div class="content" [ngClass]="{ 'sidebarExpanded': sidebarExpanded, 'justify-content-center': hideComponent }">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>