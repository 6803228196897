import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class contactService {

    private baseUrl: string = environment.baseUrl;

    constructor() { }

    contactService(userData: any) {
        
        const token = sessionStorage.getItem('tokenSession');

        const headers: any = {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff'
        };
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        return window.fetch(`${this.baseUrl}/Main/AgregarDatosContacto`, {
            method: 'POST',
            body:  JSON.stringify(userData),
            headers: headers
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error en la solicitud: ' + response.status);
                }
                return response.json(); // Devuelve los datos de la segunda solicitud

            })
            .catch(error => {
                console.error('Error en la solicitud:', error);
                return throwError('Hubo un error en la solicitud. Por favor, inténtalo de nuevo.');
            });
    }
}