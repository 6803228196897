import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  sidebarExpanded = true;
  hideComponent: boolean = true;

  constructor(
    private router: Router,
    private el: ElementRef
    ) {
      this.hideComponent = true;
    }
  
  closeModal() {
    this.el.nativeElement.querySelector('.modal').style.display = 'none';
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkRoute();
      }
    });
  }

  private checkRoute(): void {
    const currentRoute = this.router.url;
    this.hideComponent = (
      currentRoute !== '/paso1' &&
      currentRoute !== '/paso2' &&
      currentRoute !== '/paso3'
    );
  }
}
