import { Component, Input, ElementRef, AfterViewInit, input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.css']
})
export class ModalInfoComponent {
  idTicket: string = '';
  @Input() title: string;
  @Input() subtitle: string;
  @Input() type: string;
  @Input() content: string;
  @Input() showContent: boolean;
  @Input() listContent: [];
  @Input() detail: boolean;
  @Input() icon: string;
  
  constructor(
    public activeModal: NgbActiveModal,
    private elRef: ElementRef,
    private router: Router
    ) {
      if(sessionStorage.getItem('idTicket')) {
        this.idTicket = sessionStorage.getItem('idTicket');
      }
    }

  closeModal() {
    this.activeModal.close();
  }
  
  finishProcess() {
    this.closeModal();
    this.router.navigate(['/']);
    sessionStorage.clear();
  }
}
