import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  validaRut(rutSucio: string) {
    var rutCompleto = rutSucio.replace('.', '');
    rutCompleto = rutCompleto.replace('.', '');
    rutCompleto = rutCompleto.replace('-', '');
    var verif = rutCompleto.substring(rutCompleto.length, rutCompleto.length - 1);
    var rutVal = rutCompleto.substring(0, rutCompleto.length - 1);

    if (!/^[0-9]{7,8}/.test(rutVal)) {
      return false;
    } else {
      if (!/^[0-9kK]{1}$/.test(verif)) {
        return false;
      } else {

        let rut = rutVal.split('');
        const rutValid = rut.reverse();

        var i = 2;
        var suma = 0;
        rutValid.forEach(element => {
          suma = suma + (Number(element) * i);
          i = i + 1;;
          if (i == 8) i = 2;
          //
        });

        var digito = (11 - (suma - (Math.trunc(suma / 11) * 11)));
        if (digito == 10 && (verif == 'k' || verif == 'K')) {
          return true;
        } else {
          if (digito == 11 && Number(verif) == 0) {
            return true;
          } else {
            if (digito == Number(verif)) {
              return true;
            }
          }
        }

      }
      return false;
    }
  }
}
