<div class="sidebar" [ngClass]="{ 'expanded': isExpanded }">
    <div class="header">
        <img [src]="isExpanded ? expandedLogo : collapsedLogo" (click)="handleSidebarToggle()" />
    </div>
    

    <div class="stepper-content">
        <div class="title-container">
            <h1 class="text-center">Simular</h1>
            <h1 class="text-center">en línea</h1>
        </div>

        <div class="content">
            <div class="link">
                <div class="content">
                    <div class="icon">
                        <div class="step-indicator" [ngClass]="{ 'active': isRouteActive('/paso1') }">1</div>
                        <div class="dividing"></div>
                    </div>
                    <div class="title">Datos de Simulación</div>
                </div>
            </div>
            <div class="link">
                <div class="content">
                    <div class="icon">
                        <div class="step-indicator" [ngClass]="{ 'active': isRouteActive('/paso2') }">2</div>
                    </div>
                    <div class="title">Simulación</div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer"></div>
</div>