import { Router } from '@angular/router';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent {
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  expandedLogo = '../../../../../assets/images/logo_home.svg';
  collapsedLogo = '../../../../../assets/images/collapsed_logo.svg';

  constructor(private router: Router) { }

  
  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);
  
}
