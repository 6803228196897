import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalHelpComponent } from '../../modals/modal-help/modal-help.component';
import { ModalInfoComponent } from '../../modals/modal-info/modal-info.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  envioFormulario = {
    title: "",
    subtitle: "¡Hablamos pronto!",
    content: "Serás contactado por uno de nuestros asesores especialistas para guiarte en tomar la mejor decisión.",
    showContent: true,
    detail: false,
    icon: "exito"
  };

  envioFormularioTicketError = {
    title: "",
    subtitle: "¡Error al generar el Ticket!",
    content: "Por favor intentelo más tarde.",
    showContent: true,
    detail: false,
    icon: "info"
  };

  constructor(
    private modalService: NgbModal,
    private router: Router
    ) {}

  openModal() {
    const modalRef: NgbModalRef = this.modalService.open(ModalHelpComponent);

    modalRef.result.then((result) => {
      // El siguiente código se ejecutará cuando se cierre el primer modal
      if (result === 'success') {
        this.openModalInfo(this.envioFormulario);
      }
      if (result === 'idTicket-1') {
        this.openModalInfo(this.envioFormularioTicketError);
      }
    }).catch((error) => {
      
    });
  }

  openModalInfo(info: any) {
    
    const modalRef = this.modalService.open(ModalInfoComponent);
    modalRef.componentInstance.title = info.title;
    modalRef.componentInstance.subtitle = info.subtitle;
    modalRef.componentInstance.type = info.type;
    modalRef.componentInstance.content = info.content;
    modalRef.componentInstance.showContent = info.showContent;
    modalRef.componentInstance.listContent = info.listContent;
    modalRef.componentInstance.detail = info.detail;
    modalRef.componentInstance.icon = info.icon;

    modalRef.dismissed.subscribe(() => {
      if(sessionStorage.getItem('idTicket')){
        this.router.navigate(['/']);
        sessionStorage.clear();
      }
    });
  }

  ngOnInit(): void {
  }

}
